<template>
  <div>
    <b-sidebar id="sidebar-add-receipt" sidebar-class="sidebar-lg" :visible="commentSidebar.visible" bg-variant="white"
      shadow backdrop no-header right @change="(val) => mutateCommentSidebar({ visible: val })" @hidden="clear">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Resolução de ticket</h4>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="resolve" @reset.prevent="resetForm">
          <b-form-group label="Comentário" label-for="comment-textarea">
            <b-form-textarea
              id="comment-textarea"
              v-model="comment"
              placeholder="Digite aqui seu comentário para resolução dos tickets..."
              rows="3"
              max-rows="6">
            </b-form-textarea>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex justify-content-end mt-2">
            <b-button :disabled="saving" variant="primary" type="submit">
              <b-spinner v-if="saving" small />
              {{ saving ? 'Resolvendo...' : 'Resolver' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormTextarea, BButton } from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import * as types from '../store/types'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BButton
  },
  data() {
    return {
      saving: false,
      comment: ''
    }
  },
  computed: {
    ...mapGetters({
      commentSidebar: types.RESOLVE_TICKET_SIDEBAR,
    })
  },
  methods: {
    ...mapMutations({
      mutateCommentSidebar: types.MUTATE_RESOLVE_TICKET_SIDEBAR,
      resolveTicket: types.RESOLVE_TICKET
    }),
    ...mapActions({
      resolveTicket: types.RESOLVE_TICKET
    }),
    resolve(){
      if (!this.comment.trim()) {
        this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Comentário é um campo obrigatório",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        return;
      }
      this.saving = true;
      if(typeof this.commentSidebar.id === 'object'){
        var payload = {
          ticket_ids: this.commentSidebar.id,
          comment: this.comment
        }
      }else{
        var payload = {
          ticket_id: this.commentSidebar.id,
          comment: this.comment
        }
      }
      this.resolveTicket({
        ...payload
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao mudar finalizar o ticket. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.commentSidebar.saveAction()
          this.mutateCommentSidebar({ visible: false, id: undefined })
          this.saving = false;
          this.clear();
        });
    },
    clear() {
      this.comment = ''
    }
  },
  setup() {
    return { toast: useToast() };
  }
}
</script>
